.Mui-error:after {
  -webkit-transform: scaleX(1) translateX(0) !important;
  -moz-transform: scaleX(1) translateX(0) !important;
  -ms-transform: scaleX(1) translateX(0) !important;
  transform: scaleX(1) translateX(0) !important;
  border-bottom: 2px solid;
  border-bottom-color: #bc3c44;
}

body * {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important; 
}

html {
  position: fixed
}